
const IMGURL = "http://image.mhito.net/4g/";

// // 测试
// const SERVER = 'https://mh4go.mhito.net/api/'
// const URL = 'https://mh4go.mhito.net/'
// const WSURL = 'wss://mh4go.mhito.net/ws'

// 正式
const SERVER = "https://mh62.mhito.net/api/";
const URL = "https://mh62.mhito.net.net/";
const WSURL = "wss://mh62.mhito.net/ws";

const VERISON = "4.20231205";
const V = "v4.0.19";
const NOTIFYTPLS = {
  orderNotify: "",
};

module.exports = {
  SERVER: SERVER,
  URL: URL,
  VERISON: VERISON,
  V: V,
  IMGURL: IMGURL,
  WSURL: WSURL,
  NOTIFYTPLS: NOTIFYTPLS,
};
